import { makeStyles } from '@material-ui/core';
import React from 'react';
import content from '../../../content/index/action.json';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions, { defaultWidth } from '../../../styles/dimensions';
// @ts-ignore
import PosterImg from '../../../images/index/action/poster-2.png';
// @ts-ignore
import Video from '../../../videos/Easyfitness.mp4';

const Action = () => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<h2 className={classes.header}>{content.title}</h2>
			<p className={classes.subheader}>{content.subtitle}</p>

			<video controls preload="none" className={classes.video} poster={PosterImg}>
				<source src={Video} type="video/mp4" />
			</video>
		</section>
	);
};

export default Action;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultWidth,
		margin: '0 auto',
		paddingTop: '5.625em',
		paddingBottom: '7.375em',
		'@media (max-width: 959px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingBottom: 0,
		},
		'@media (max-width: 599px)': {
			paddingBottom: 0,
		},
	},
	header: {
		textAlign: 'center',
		color: Colors.textBlack,
		'@media (max-width: 599px)': {
			whiteSpace: 'pre-wrap',
		},
	},
	subheader: {
		textAlign: 'center',
		color: Colors.textGray,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
	},
	video: {
		width: '80%',
		marginLeft: '10%',
		marginRight: '10%',
		outline: 0,
		'@media (min-width: 1200px)': {
			height: '540px',
		},
		'@media (max-width: 1199px)': {
			height: 'calc(80vw * 0.5625)',
		},
		'@media (max-width: 959px)': {
			width: '100%',
			height: 'calc(100vw * 0.5625)',
			marginLeft: 0,
			marginRight: 0,
		},
	},
});

import { makeStyles } from '@material-ui/core';
import React from 'react';
import content from '../../../content/index/services.json';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions from '../../../styles/dimensions';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import ArrowLeft from '../../../images/index/services/arrowLeft';
import ArrowRight from '../../../images/index/services/arrowRight';
import { StaticImage } from 'gatsby-plugin-image';

const Services = () => {
	const [slidePercantage, setSlidePercentage] = React.useState(33.33333333);

	const classes = useClasses();

	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			if (window.innerWidth < 600) {
				setSlidePercentage(85);
			} else if (window.innerWidth < 992) {
				setSlidePercentage(50);
			}

			const handleResize = () => {
				if (window.innerWidth < 600) {
					setSlidePercentage(85);
				} else if (window.innerWidth < 992) {
					setSlidePercentage(50);
				}
			};

			window.addEventListener('resize', handleResize, { passive: true });

			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}
	}, []);

	const images = React.useMemo(() => {
		return [
			<StaticImage
				src="../../../images/index/services/Freihantelbereich.png"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Freihantelbereich-B.jpg"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Fit 22 Zirkel.png"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Toiletten.png"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Geratepark.png"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Functional Bereich.png"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Duschen.png"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Solarium.png"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Wassermassage.png"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Cardiopark.png"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Kursraum.jpg"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
			<StaticImage
				src="../../../images/index/services/Getrankebar.jpg"
				alt=""
				layout="constrained"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
			/>,
		];
	}, []);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<h2 className={classes.header}>{content.title}</h2>
				<p className={classes.subheader}>{content.subtitle}</p>

				<Carousel
					showThumbs={false}
					showStatus={false}
					centerMode={true}
					centerSlidePercentage={slidePercantage}
					infiniteLoop={true}
					swipeable={true}
					emulateTouch={true}
					className={classes.carousel}
					renderArrowPrev={(onClickHandler, hasPrev) =>
						hasPrev && (
							<div onClick={onClickHandler} className={classes.arrowPrevious}>
								<ArrowLeft />
							</div>
						)
					}
					renderArrowNext={(onClickHandler, hasNext) =>
						hasNext && (
							<div onClick={onClickHandler} className={classes.arrowNext}>
								<ArrowRight />
							</div>
						)
					}>
					{content.tabs.map((item, key) => (
						<div key={key} className={classes.carouselTab}>
							{images[key]}
							<h3 className={classes.carouselText}>{item.text}</h3>
						</div>
					))}
				</Carousel>
			</div>
		</section>
	);
};

export default Services;

const useClasses = makeStyles({
	section: {
		backgroundColor: Colors.backgroundBlack,
		transform: 'skewY(-3deg)',
		paddingTop: '5em',
		paddingBottom: '8em',
		marginTop: '2em',
	},
	container: {
		transform: 'skewY(3deg)',
	},
	header: {
		textAlign: 'center',
		color: Colors.white,
		marginTop: 0,
		marginBottom: '0.3em',
	},
	subheader: {
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		textAlign: 'center',
		color: Colors.whiteDimmed,
		margin: 0,
	},
	carousel: {
		'& li:not(.dot)': {
			// transform: 'translateX(-33.33333333%)',
			backgroundColor: Colors.backgroundBlack + ' !important',
		},
	},
	carouselTab: {
		padding: '4.25em 1em',
		'@media (max-width: 599px)': {
			padding: '4.25em 0.375em',
		},
	},
	carouselText: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		margin: 0,
		color: Colors.white,
		'@media (max-width: 599px)': {
			fontSize: '1.25rem',
		},
	},
	arrowPrevious: {
		position: 'absolute',
		zIndex: 2,
		top: 'calc(50% - 15px)',
		width: 30,
		height: 30,
		cursor: 'pointer',
		left: '4em',
		transform: 'translateY(-50%)',
		'@media (max-width: 599px)': {
			left: '1em',
			transform: 'translateY(-25%) scale(0.5)',
		},
	},
	arrowNext: {
		position: 'absolute',
		zIndex: 2,
		top: 'calc(50% - 15px)',
		width: 30,
		height: 30,
		cursor: 'pointer',
		right: '4em',
		transform: 'translateY(-50%)',
		'@media (max-width: 599px)': {
			right: '1em',
			transform: 'translateY(-25%) scale(0.5)',
		},
	},
});

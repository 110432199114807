import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import content from '../../../content/index/feedback.json';
import Dimensions, { defaultWidth } from '../../../styles/dimensions';
import Colors from '../../../styles/colors';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import { Carousel } from 'react-responsive-carousel';
import ArrowLeft from '../../../images/index/services/arrowLeft';
import ArrowRight from '../../../images/index/services/arrowRight';
import { GoogleReview } from '../../../pages/index';
import { Link } from 'gatsby';
// @ts-ignore
import TrianglesImg from '../../../images/index/feedback/triangles_group.svg';

interface Props {
	googleReviews: GoogleReview[];
	handleLinkClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Feedback = (props: Props) => {
	const { googleReviews, handleLinkClick } = props;

	const classes = useClasses();

	return (
		<section className={classes.section}>
			<img src={TrianglesImg} alt="" loading="lazy" className={classes.triangles} />
			<Grid container>
				<Grid item md={6}>
					<h2 className={classes.header}>{content.title}</h2>
					<p className={classes.subheader}>{content.subtitle}</p>

					<Link to={content.register.src} className={classes.link} onClick={handleLinkClick}>
						{content.register.text}
					</Link>
				</Grid>
				<Grid item md={6} className={classes.carouselColumn}>
					<Carousel
						className={classes.quote}
						showThumbs={false}
						showStatus={false}
						infiniteLoop={true}
						swipeable={true}
						emulateTouch={true}
						showIndicators={false}
						dynamicHeight={true}
						renderArrowPrev={(onClickHandler, hasPrev) =>
							hasPrev && (
								<div onClick={onClickHandler} className={classes.arrowPrevious}>
									<ArrowLeft />
								</div>
							)
						}
						renderArrowNext={(onClickHandler, hasNext) =>
							hasNext && (
								<div onClick={onClickHandler} className={classes.arrowNext}>
									<ArrowRight />
								</div>
							)
						}>
						{googleReviews.map((review, key) => (
							<div key={key} className={classes.feedbackContainer}>
								<p className={classes.feedbackText}>{review.body}</p>

								{/* <p className={classes.rating}>Bewertung: {review.rating} / 5</p> */}

								<div className={classes.badge}>
									<img src={review.sourceImage} alt="" loading="lazy" className={classes.avatar} />
									<div className={classes.info}>
										<span className={classes.name}>{review.source}</span>
										<span className={classes.accreditation}>{review.date}</span>
									</div>
								</div>
							</div>
						))}
					</Carousel>

					<div>
						<h3 className={classes.header}>{content.openningHours.title}</h3>
						<p className={classes.oppeningHoursText}>{content.openningHours.workdays}</p>
						<p className={classes.oppeningHoursText}>{content.openningHours.weekends}</p>
						<p className={classes.oppeningHoursText}>{content.openningHours.holidays}</p>
						<p className={classes.oppeningHoursText}>{content.openningHours.open}</p>
					</div>
				</Grid>
			</Grid>
		</section>
	);
};

export default Feedback;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultWidth,
		margin: '0 auto',
		paddingTop: '10.5em',
		paddingBottom: '9.125em',
		'@media (max-width: 1231px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
		'@media (min-width: 600px) and (max-width: 959px)': {
			paddingLeft: 'calc(12/8 * 8.33333333% + 1em)',
			paddingRight: 'calc(12/8 * 8.33333333% + 1em)',
		},
		'@media (max-width: 599px)': {
			paddingTop: '7.25em',
			paddingBottom: '4em',
		},
	},
	header: {
		color: Colors.textBlack,
		marginTop: '0.4em',
		marginBottom: '0.3em',
	},
	subheader: {
		color: Colors.textGray,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		margin: '0.6em 0',
	},
	quote: {
		marginBottom: '17em',
		'& li:not(.dot)': {
			backgroundColor: 'transparent',
		},
		'& li.dot': {
			backgroundColor: Colors.textGray + ' !important',
		},
		'& li.dot:not(.selected)': {
			opacity: 0.5,
		},
		'@media (max-width: 959px)': {
			marginBottom: '9.625em',
		},
	},
	feedbackContainer: {
		paddingBottom: '4em',
	},
	feedbackText: {
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.textGray,
		whiteSpace: 'pre-wrap',
		textAlign: 'left',
	},
	// rating: {
	// 	textAlign: 'left',
	// 	fontFamily: FiraSans.semiBold,
	// 	fontSize: Dimensions.smallText.fontSize,
	// 	lineHeight: Dimensions.smallText.lineHeight,
	// 	color: Colors.textGray,
	// },
	badge: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginTop: '1.5em',
		textAlign: 'left',
		paddingBottom: '1.5em',
	},
	avatar: {
		width: 'initial !important',
		verticalAlign: 'middle !important',
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '0.75em',
	},
	name: {
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.textBlack,
	},
	accreditation: {
		fontFamily: FiraSans.light,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.textGray,
	},
	oppeningHoursText: {
		color: Colors.textGray,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		'@media (max-width: 599px)': {
			whiteSpace: 'pre-wrap',
		},
	},
	triangles: {
		position: 'absolute',
		left: 0,
		transform: 'translateY(-10.5em)',
		zIndex: -1,
		maxWidth: '100%',
	},
	carouselColumn: {
		'@media (max-width: 959px)': {
			maxWidth: '100%',
		},
	},
	arrowPrevious: {
		position: 'absolute',
		zIndex: 2,
		top: 'calc(100% - 15px)',
		width: 30,
		height: 30,
		cursor: 'pointer',
		left: 0,
		transform: 'translateY(-25px) scale(0.5)',
		'& svg g': {
			stroke: Colors.textGray,
		},
	},
	arrowNext: {
		position: 'absolute',
		zIndex: 2,
		top: 'calc(100% - 15px)',
		width: 30,
		height: 30,
		cursor: 'pointer',
		right: 0,
		transform: 'translateY(-25px) scale(0.5)',
		'& svg g': {
			stroke: Colors.textGray,
		},
	},
	link: {
		display: 'inline-block',
		borderRadius: '8px',
		padding: '0.875em',
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginTop: '3em',
	},
});

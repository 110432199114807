import { Grid, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import content from '../../../content/index/advantages.json';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions, { defaultWidth } from '../../../styles/dimensions';
// @ts-ignore
import Icon1 from '../../../images/index/advantages/uber_1000_m2_trainingsflache.svg';
// @ts-ignore
import Icon2 from '../../../images/index/advantages/freihantelbereich.svg';
// @ts-ignore
import Icon3 from '../../../images/index/advantages/kostenlose_parkplatze.svg';
// @ts-ignore
import Icon4 from '../../../images/index/advantages/wassermassageliege.svg';
// @ts-ignore
import Icon5 from '../../../images/index/advantages/solarium.svg';
// @ts-ignore
import Icon6 from '../../../images/index/advantages/vielseitiger_cardiopark.svg';
// @ts-ignore
import Icon7 from '../../../images/index/advantages/functionaltraining.svg';
// @ts-ignore
import Icon8 from '../../../images/index/advantages/kurse.svg';
// @ts-ignore
import Icon9 from '../../../images/index/advantages/duschen.svg';
// @ts-ignore
import Icon10 from '../../../images/index/advantages/matrix_kraftgerate.svg';
// @ts-ignore
import Icon11 from '../../../images/index/advantages/trainingsplanerstellung.svg';
// @ts-ignore
import Icon12 from '../../../images/index/advantages/personaltraining.svg';
// @ts-ignore
import Icon13 from '../../../images/index/advantages/kostenlose_trainingsapp.svg';
// @ts-ignore
import Icon14 from '../../../images/index/advantages/getrankestation.svg';
// @ts-ignore
import Icon15 from '../../../images/index/advantages/power_plate_training.svg';
// @ts-ignore
import Icon16 from '../../../images/index/advantages/fit22_zirkel.svg';
// @ts-ignore
import Icon17 from '../../../images/index/advantages/trxtraining.svg';

interface Props {
	handleLinkClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Advantages = (props: Props) => {
	const { handleLinkClick } = props;

	const classes = useClasses();

	const images = React.useMemo(() => {
		return [
			<img src={Icon1} alt="" loading="lazy" />,
			<img src={Icon2} alt="" loading="lazy" />,
			<img src={Icon3} alt="" loading="lazy" />,
			<img src={Icon4} alt="" loading="lazy" />,
			<img src={Icon5} alt="" loading="lazy" />,
			<img src={Icon6} alt="" loading="lazy" />,
			<img src={Icon7} alt="" loading="lazy" />,
			<img src={Icon8} alt="" loading="lazy" />,
			<img src={Icon9} alt="" loading="lazy" />,
			<img src={Icon10} alt="" loading="lazy" />,
			<img src={Icon11} alt="" loading="lazy" />,
			<img src={Icon12} alt="" loading="lazy" />,
			<img src={Icon13} alt="" loading="lazy" />,
			<img src={Icon14} alt="" loading="lazy" />,
			<img src={Icon15} alt="" loading="lazy" />,
			<img src={Icon16} alt="" loading="lazy" />,
			<img src={Icon17} alt="" loading="lazy" />,
		];
	}, []);

	return (
		<section className={classes.section}>
			<h2 className={classes.header}>{content.title}</h2>

			<Grid container>
				{content.advantages.map((item, key) => (
					<React.Fragment key={key}>
						<Grid
							item
							xs={4}
							sm={2}
							md={1}
							className={key % 3 === 0 ? classes.iconGridItem : classes.translatedIconGridItem}>
							{images[key]}
						</Grid>
						<Grid
							item
							xs={8}
							sm={4}
							md={3}
							className={key % 3 === 0 ? classes.textGridItem : classes.translatedTextGridItem}>
							<span className={classes.textSpan}>{item.text}</span>
						</Grid>
					</React.Fragment>
				))}
				<Grid item xs={12} className={classes.linkGridItem}>
					<Link to={content.register.src} className={classes.link} onClick={handleLinkClick}>
						{content.register.text}
					</Link>
				</Grid>
			</Grid>
		</section>
	);
};

export default Advantages;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultWidth,
		margin: '0 auto',
		paddingTop: '5em',
		paddingBottom: '6.5em',
		overflowX: 'hidden',
		'@media (max-width: 1231px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
		'@media (max-width: 959px)': {
			paddingTop: '18em',
		},
		'@media (max-width: 599px)': {
			paddingTop: '23em',
			paddingBottom: '4.5em',
		},
		'@media (min-width: 960px)': {
			paddingTop: '18em',
		},
	},
	header: {
		color: Colors.textBlack,
		textAlign: 'center',
	},
	textSpan: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.textGray,
	},
	iconGridItem: {
		margin: '1.75em 0',
		'@media (max-width: 599px)': {
			margin: '1em 0',
		},
	},
	translatedIconGridItem: {
		margin: '1.75em 0',
		'@media (max-width: 599px)': {
			margin: '1em 0',
		},
		'@media (min-width: 960px)': {
			position: 'relative',
			left: '4.166666665%',
		},
	},
	textGridItem: {
		alignSelf: 'center',
		margin: '1.75em 0',
		'@media (max-width: 599px)': {
			margin: '1em 0',
		},
	},
	translatedTextGridItem: {
		alignSelf: 'center',
		margin: '1.75em 0',
		'@media (max-width: 599px)': {
			margin: '1em 0',
		},
		'@media (min-width: 960px)': {
			position: 'relative',
			left: '4.166666665%',
		},
	},
	linkGridItem: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '4em',
	},
	link: {
		display: 'inline-block',
		borderRadius: '8px',
		padding: '0.875em 2.125em',
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
	},
});

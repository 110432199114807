import React from 'react';
import Layout from '../components/layout';
import Action from '../components/sections/index/action';
import Advantages from '../components/sections/index/advantages';
import Feedback from '../components/sections/index/feedback';
import Intro from '../components/sections/index/intro';
import Register from '../components/sections/index/register';
// import Schedule from '../components/sections/index/schedule';
import Services from '../components/sections/index/services';
import SEO from '../components/seo';
import { graphql } from 'gatsby';

export interface GoogleReview {
	body: string;
	// rating: number;
	source: string;
	sourceImage: string;
	date: string;
}

export interface GoogleReviewsResponse {
	allGoogleReview: {
		nodes: GoogleReview[];
	};
}

interface Props {
	data: GoogleReviewsResponse;
	[key: string]: any;
}

export const googleReviewsQuery = graphql`
	query GoogleReviewsIndex {
		allGoogleReview {
			nodes {
				body
				source
				sourceImage
				date
			}
		}
	}
`;

const IndexPage = ({ data }: Props) => {
	const handleLinkClick = React.useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (typeof window !== 'undefined') {
			document.getElementById('___gatsby')?.scroll({ top: 0, behavior: 'auto' });
		}
	}, []);

	return (
		<Layout>
			<SEO title="Home" />

			<Intro handleLinkClick={handleLinkClick} />
			<Advantages handleLinkClick={handleLinkClick} />
			<Services />
			<Action />
			{/* <Schedule /> */}
			<Feedback googleReviews={data.allGoogleReview.nodes} handleLinkClick={handleLinkClick} />
			<Register handleLinkClick={handleLinkClick} />
		</Layout>
	);
};

export default IndexPage;

import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import content from '../../../content/index/register.json';
import { Link } from 'gatsby';
import Colors from '../../../styles/colors';
import Dimensions, { defaultWidth } from '../../../styles/dimensions';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
	handleLinkClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Register = (props: Props) => {
	const { handleLinkClick } = props;

	const classes = useClasses();

	return (
		<section className={classes.section}>
			<Grid container className={classes.grid}>
				<StaticImage
					src="../../../images/index/register/jetzt_anmelden.jpg"
					alt=""
					layout="constrained"
					loading="lazy"
					placeholder="blurred"
					objectFit="cover"
					objectPosition="22% 32%"
					className={classes.image}
				/>
				<Grid item md={8}></Grid>

				<Grid item md={4} className={classes.gridItem}>
					<h2 className={classes.header}>{content.title}</h2>

					<Link to={content.button.src} className={classes.link} onClick={handleLinkClick}>
						{content.button.text}
					</Link>
				</Grid>
			</Grid>
		</section>
	);
};

export default Register;

const useClasses = makeStyles({
	section: {
		position: 'relative',
	},
	grid: {
		maxWidth: defaultWidth,
		margin: '0 auto',
		height: '80vh',
		'@media (max-width: 959px)': {
			justifyContent: 'center',
		},
	},
	gridItem: {
		alignSelf: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		'@media (max-width: 959px)': {
			alignSelf: 'flex-end',
			alignItems: 'center',
			position: 'relative',
			top: '-4em',
		},
	},
	header: {
		color: Colors.white,
		marginTop: 0,
		marginBottom: '0.625em',
	},
	link: {
		borderRadius: '8px',
		padding: '0.875em 2.125em',
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		'@media (max-width: 959px)': {
			margin: '0 auto',
		},
	},
	image: {
		// @ts-ignore
		position: 'absolute !important',
		left: 0,
		right: 0,
		height: '100%',
		zIndex: -1,
	},
});

import { makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import content from '../../../content/index/intro.json';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions from '../../../styles/dimensions';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
	handleLinkClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Intro = (props: Props) => {
	const { handleLinkClick } = props;

	const classes = useClasses();

	return (
		<section className={classes.section}>
			<StaticImage
				src="../../../images/index/intro/K2_2024_Titelbild.jpg"
				alt=""
				layout="constrained"
				loading="eager"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="50% 50%"
				className={classes.banner}
				imgClassName={classes.bannerImage}
			/>

			<StaticImage
				src="../../../images/offer/mobileEasy12.jpg"
				alt=""
				layout="constrained"
				loading="eager"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="95% 80%"
				className={classes.mobileBanner}
			/>

			<div className={classes.container}>
				<div className={classes.headerContainer}>
					<h1 className={classes.header}>{content.title}</h1>

					<Link to={content.register.src} className={classes.link} onClick={handleLinkClick}>
						{content.register.text}
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Intro;

const useClasses = makeStyles({
	section: {
		height: 'calc(100vh - 6.25em)',
		position: 'relative',
	},
	banner: {
		height: 'calc(100vh - 6.25em)',
		width: '100%',
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
	},
	mobileBanner: {
		height: 'calc(100vh - 6.25em)',
		'@media (min-width: 600px)': {
			display: 'none !important',
		},
	},
	bannerImage: {
		'@media (max-aspect-ratio: 1.5)': {
			objectPosition: '35% 50% !important',
		},
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		bottom: 0,
		left: '50%',
		transform: 'translate(-50%, calc(100% - 12px))',
		'@media (max-width: 599px)': {
			height: '320px',
			width: 'calc(100% + 1px)',
			backgroundColor: Colors.textBlack,
			transform: 'translate(calc(-50% - 1px), calc(100% - 12px)) skewY(-2deg)',
			borderTop: `12px solid ${Colors.limeGreen}`,
			borderBottom: `12px solid ${Colors.limeGreen}`,
			backfaceVisibility: 'hidden',
			outline: `1px solid ${Colors.limeGreen}`,
		},
	},
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: Colors.textBlack,
		transform: 'skewX(20deg)',
		width: '35em',
		padding: '3em 0em',
		borderTop: `12px solid ${Colors.limeGreen}`,
		borderBottom: `12px solid ${Colors.limeGreen}`,
		'@media (max-width: 779px)': {
			width: '29em',
		},
		'@media (max-width: 599px)': {
			width: '100%',
			transform: 'skewY(2deg)',
			border: 0,
			backgroundColor: 'initial',
		},
	},
	header: {
		color: Colors.white,
		whiteSpace: 'pre-wrap',
		transform: 'skewX(-20deg)',
		margin: 0,
		marginBottom: '0.7em',
		textAlign: 'center',
		position: 'relative',
		left: '0.5em',
		'@media (max-width: 599px)': {
			transform: 'unset',
			left: 0,
		},
	},
	link: {
		display: 'inline-block',
		borderRadius: '8px',
		padding: '0.875em 2.125em',
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		transform: 'skewX(-20deg)',
		position: 'relative',
		left: '-1.25em',
		'@media (max-width: 599px)': {
			transform: 'unset',
			left: 0,
		},
	},
});
